import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Cookies } from 'react-cookie';
import classnames from 'classnames';
import get from 'lodash/get';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';
import { injectIntl } from 'react-intl';
import {
  SeoContent as InterestingInformation,
  WordsmithContent
} from '@dmm/react-common-components';
import { adsConfig, getTargeting } from './resources/adsConfig';
import adParams from './resources/adParams';
import { isMobileResolution } from '../../../utils/commonHelper';
import AdProvider from '../../../components/Ads/AdProvider';
import DynamicContent from '../components/DynamicContent';
import ExpertReviewsTitle from '../components/ExpertReviewsTitle';
import MobileRefineSearch from '../components/MobileRefineSearch';
import OEMDetails from '../components/OEMDetails';
import RefineSearchSideBar from '../components/RefineSearchSideBar';
import RelatedBoatArticles from '../components/RelatedBoatArticles';
import RelatedContent from '../components/RelatedContent';
import ResultsHeader from '../components/ResultsHeader';
import ResultsPagination from '../components/ResultsPagination';
import SeoContent from '../components/SeoContent';
import TypeChooser from '../components/TypeChooser';
import TypeChooserBranded from '../components/TypeChooser/Branded';
import YoutubeVideos from '../components/YoutubeVideos';
import AlertMessage from '../../../components/AlertMessage';
import BrandedHeader from '../../BrandedSearch/components/BrandedHeader';
import BrandedHeaderV2 from '../../BrandedSearch/components/BrandedHeaderV2';
import BreadCrumb from '../../../components/BreadCrumb';
import Meta from '../../../components/Meta';
import VehicleSchema from '../../../components/Schema/VehicleSchema';
import { getBreadCrumb } from '../utils/breadcrumb';
import { getBannerImage } from '../../../utils/partiesHelper';
import * as meta from '../utils/meta';
import {getBrokerSpotlightInfo, getListingLength, getListingSubdivision} from '../../../utils/listingHelper';
import {
  isSingleTypeOrClassFilter,
  isSingleMakeFilter
} from '../../../utils/seoContentHelper';

import {
  setProductImpression,
  addLead,
  setEnhancedEcommerce,
  trackSearchCount,
  setListingClick
} from '../../../store/actions/dataLayer';
import {
  getActiveParams,
  getDefaultParams, parseSearchParams
} from '../../../utils/urlHelpers/boats';
import { getNumberOfPages } from '../../../utils/paginationHelper';
import { getBoatUrl } from '../../../utils/urlHelpers/boat';
import { listingPropTypes } from '../../../utils/commonPropTypes';
import { getConfig, PortalConfigContext } from '../../../config/portal';
import * as utils from '../../../store/utils';
import * as storage from '../../../utils/storage';
import {
  REDUCTION_REDIRECT_COOKIE,
  UNAVAILABILITY_REDIRECT_COOKIE
} from '../../../constants/cookies';

import { getAddress } from '../../../utils/commonHelper';
import {
  getCurrentLocale,
  getPortalName
} from '../../../utils/language';
import { BrokerSpotlightCard } from '@dmm/lib-react-broker-card';
import '@dmm/lib-react-broker-card/dist/@dmm/lib-react-broker-card.min.css';
import ContactForm from '../../../components/ContactForm';
import { getCustomUom } from '../../../utils/uomHelper';
import HeroImage from '../HeroImage';
import PaymentCalculator from '../../../components/PaymentCalculator';
import { ENGLISH_LOCALES } from '../../../constants';
import { ThreeColumnListings } from './components/ThreeColumnListings';

import '@dmm/lib-react-videos/dist/@dmm/lib-react-videos.min.css';
import './styles.css';
import {getBoatConstantsFromI18n} from '../../../tppServices/translations/constants';
import { getMessages } from '../../../tppServices/translations/messages';
import { getBoatLoanConstants } from '../../../constants/BoatLoans';
import {
  THREE_COL_LISTINGS_SRP
} from '../../../utils/domParserHelper';
import { getPageAdsData } from '../../../utils/ads/adsUtils';
import { FEATURE_FLAG_KEYS, isFeatureFlagActive } from '../../../context/ABTestContext';

class MainContentV2 extends PureComponent {
  displayContactFormData = [];

  state = {
    alert: null,
    isMobileResolution: false,
    contactOpen: false,
    listingContact: undefined,
    adRefreshKey: 0,
    isMounted: false,
    filterState: undefined,
    mobileAdWidth: 0
  };
  constructor(props) {
    super(props);
    this.translationMessages = getMessages();
    this.boatLoansConstants = getBoatLoanConstants(this.context);
  }

  populateAlertMessage = () => {
    const messages = this.translationMessages;
    const {
      cookies,
      intl: { formatMessage: t }
    } = this.props;

    if (cookies.cookies[REDUCTION_REDIRECT_COOKIE]) {
      this.setState({
        alert: {
          mainText: t(messages.alertMessage.linkExpired.title),
          secondaryText: t(messages.alertMessage.linkExpired.description),
          url: get(this.props, 'location.pathname')
        }
      });
    } else if (cookies.cookies[UNAVAILABILITY_REDIRECT_COOKIE]) {
      this.setState({
        alert: {
          mainText: t(messages.alertMessage.boatUnavailable.title),
          secondaryText: t(messages.alertMessage.boatUnavailable.description),
          url: get(this.props, 'location.pathname')
        }
      });
    }

    cookies.remove(UNAVAILABILITY_REDIRECT_COOKIE, { path: '/' });
    cookies.remove(REDUCTION_REDIRECT_COOKIE, { path: '/' });
  };

  resizeHandler = () => {
    this.setState({
      isMobileResolution: isMobileResolution()
    });
  };

  saveSearchResults = () => {
    const nextPreviousData = {};
    const searchUrl = get(this.props, 'location.pathname', '');
    nextPreviousData.searchParams = parseSearchParams(
      searchUrl,
      this.props.mode === MainContentV2.MODES.branded,
      get(this.getCustomUom(), 'length', undefined)
    );
    nextPreviousData.searchUrl = searchUrl;
    nextPreviousData.pageLow = parseInt(nextPreviousData.searchParams.page);
    const records = get(this.props, 'search.records', []);
    nextPreviousData.urls = records.map((listing) => getBoatUrl(listing));
    storage.setItem('nextPreviousData', JSON.stringify(nextPreviousData));
    if (get(getConfig(), 'supports.permutive', false)) {
      window?.permutiveHelper?.pageView && window.permutiveHelper.pageView();
    }
  };

  getCustomUom() {
    const {
      cookies,
      intl: { locale }
    } = this.props;
    const isDefaultLocaleUom = get(this.context, 'supports.defaultUoms', false);
    const supportedUoms = get(this.context, 'supportedUoms');
    const defaultLocaleUom = get(
      this.context,
      `languages.${locale}.defaultUom`
    );
    return getCustomUom(
      isDefaultLocaleUom,
      cookies,
      supportedUoms,
      defaultLocaleUom
    );
  }

  handleStickyMobileAd = throttle(() => {
    const adMobileLeaderboard = `#${adParams.mobileLeaderboard1Params.adId}`;
    if (document.querySelector(adMobileLeaderboard)) {
      let previousSiblingPosition = document.querySelector('.sticky-mobile-ad').previousSibling.getBoundingClientRect().bottom;
      if (previousSiblingPosition <= 55) {
        document.querySelector(adMobileLeaderboard).classList.add('sticky-ad');
        if (this.state.isMounted && this.state.mobileAdWidth >= 320) {
          document.querySelector(adMobileLeaderboard).classList.add('wide-ad');
        }
      } else {
        document.querySelector(adMobileLeaderboard).classList.remove('sticky-ad');
        document.querySelector(adMobileLeaderboard).classList.remove('wide-ad');
      }
    }
  }, 100);

  handleSlotRenderEnded = (event) => {
    if (event.slot.getSlotElementId() === adParams.mobileLeaderboard1Params.adId) {
      const element = document.getElementById(adParams.mobileLeaderboard1Params.adId);
      const adIframe = element.querySelector('iframe[id^="google_ads_iframe_"]');
      const width = adIframe.getBoundingClientRect().width;
      this.setState({ mobileAdWidth: width });
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
    this.populateAlertMessage();

    this.setState({ isMounted: true });
    const trackTotalSearchCountFlag = get(
      this.context,
      'supports.trackTotalSearchCount',
      false
    );
    if (trackTotalSearchCountFlag) {
      this.props.trackSearchCount(this.props.search.count);
    }
    window.addEventListener('scroll', this.handleStickyMobileAd);

    // Setup a listener for the slotRenderEnded event using an interval to check if the pubads object is available
    this.checkInterval = setInterval(() => {
      if (window.googletag && typeof window.googletag.pubads === 'function') {
        window.googletag.pubads().addEventListener('slotRenderEnded', this.handleSlotRenderEnded);
        clearInterval(this.checkInterval); // Clear the interval once we've set up the listener
      }
    }, 100);

    // Stop the interval after 4 seconds if the pubads object is not available
    /* istanbul ignore next */
    this.timeoutId = setTimeout(() => {
      clearInterval(this.checkInterval);
    }, 4000);
  }

  componentDidUpdate(prevProps) {
    if (!utils.isServer()) {
      this.saveSearchResults();
    }
    if (
      this.state.alert &&
      get(this.props, 'location.pathname') !==
      get(prevProps, 'location.pathname')
    ) {
      this.setState({ alert: null });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
    window.removeEventListener('scroll', this.handleStickyMobileAd);
    if (window.googletag && typeof window.googletag.pubads === 'function') {
      window.googletag.pubads().removeEventListener('slotRenderEnded', this.handleSlotRenderEnded);
    }
    clearInterval(this.checkInterval);
    clearTimeout(this.timeoutId);
  }

  getHeaderContent(seoParams, portal) {
    return meta.getH1({
      params: seoParams,
      portal,
      boatClasses: this.props.facets?.class,
      forceDynamic: ENGLISH_LOCALES.includes(this.props.intl.locale)
    });
  }

  handleBrokerContactButtonClick = () => {
    const boatConstants = getBoatConstantsFromI18n();
    const { spotlightDetails } = this.props;
    addLead(
      spotlightDetails.party.details.brokerSpotlightId,
      'email lead',
      null
    );
    setEnhancedEcommerce(
      [boatConstants.LISTING_PREMIUM_BROKER],
      spotlightDetails.party.details.brokerSpotlightId
    );

    this.displayContactFormData = [];
    this.setState({ contactOpen: true });
  };

  handleBrokerContactButtonClose = () => {
    this.setState({ contactOpen: false });
    this.displayContactFormData = [];
  };

  handleListingContactButtonClick = (listing) => {
    const boatConstants = getBoatConstantsFromI18n();
    const contactData = {
      boatClass: listing.class,
      make: listing.make,
      listingType: boatConstants.LISTING_ENHANCED,
      length: getListingLength(listing, this.context),
      subdivision: getListingSubdivision(listing),
    };

    this.setState({
      listingContact: (
        <ContactForm
          key={listing.id}
          itemId={listing.id}
          data={contactData}
          open={true}
          onClose={this.handleListingContactButtonClose}
          mode={ContactForm.MODES.modal}
          contactSubmitTrack
        />
      )
    });
  };
  handleListingContactButtonClose = () => {
    this.setState({ listingContact: undefined });
  };

  renderHeader(seoParams, portal, supportsBrandedHeaderV2) {
    const { mode, partyDetails, spotlightDetails } = this.props;

    if (mode === MainContentV2.MODES.branded) {
      const brokerSpotlightFeature = get(
        this.context,
        'pages.details.brokerSpotlight'
      );
      const brokerSpotlightVersion = get(
        this.context,
        'pages.details.brokerSpotlight.version',
        1
      );
      let brokerSpotlightInfo;
      if (brokerSpotlightFeature && brokerSpotlightVersion === 2) {
        brokerSpotlightInfo = getBrokerSpotlightInfo(
          partyDetails,
          spotlightDetails.party,
          this.handleBrokerContactButtonClick,
          null,
          'listing'
        );
      }
      if (brokerSpotlightVersion === 2 && brokerSpotlightInfo) {
        return <BrokerSpotlightCard brokerInfo={brokerSpotlightInfo} />;
      } else if (supportsBrandedHeaderV2) {
        return (
          <BrandedHeaderV2
            bannerPath={getBannerImage(partyDetails.bannerPath)}
            description={partyDetails.description}
            multiLangDescriptions={partyDetails.multiLangDescriptions}
            logoPath={partyDetails.logoPath}
            displayName={partyDetails.displayName}
            seoParams={seoParams}
            portal={portal}
            address={partyDetails.address}
            mode={BrandedHeaderV2.SEARCH_TYPE.boats}
          />
        );
      }
      return (
        <BrandedHeader
          bannerPath={partyDetails.bannerPath}
          description={partyDetails.description}
          multiLangDescriptions={partyDetails.multiLangDescriptions}
          logoPath={partyDetails.logoPath}
          logoUrl={partyDetails.logoUrl}
          displayName={partyDetails.displayName}
          seoParams={seoParams}
          portal={portal}
          address={partyDetails.address ? getAddress(partyDetails.address) : ''}
        />
      );
    }

    return <h1>{this.getHeaderContent(seoParams, portal)}</h1>;
  }

  getSponsoredBoats() {
    const sponsored = get(this.props, 'sponsored.records', []);

    // TODO - if 3 column goes permanent, fix this patch fix to request 3 sponsoredBoats only
    return sponsored
      .map((listing) => {
        listing.href = getBoatUrl(listing);
        return listing;
      })
      .slice(0, 3);
  }

  getFuzzySponsoredBoats() {
    const fuzzySponsored = get(this.props, 'fuzzySponsoredBoats.records', []);

    return fuzzySponsored
      .map((listing) => {
        listing.href = getBoatUrl(listing);
        return listing;
      });
  }

  getDealerWithCityText = (dealerName, dealerCity) => {
    const messages = this.translationMessages;
    const {
      intl: { formatMessage: t }
    } = this.props;
    return t(messages.brandedSearch.SEO.breadcrumbs, {
      dealerName,
      dealerCity
    });
  };

  getPriceDisclaimer() {
    const {
      intl: { locale }
    } = this.props;
    return get(this.context, `languages.${locale}.currency.disclaimer`, false);
  }

  getSeoContentH2Text = (interestingInformation) => {
    const h2 = interestingInformation.linkText;
    return meta.cleanText(h2);
  };

  readOnInterestingInformation = (interestingInformation) => {
    const messages = this.translationMessages;
    const {
      intl: { formatMessage: t }
    } = this.props;
    const linkText = meta.cleanText(interestingInformation.linkText);

    interestingInformation.linkText = t(
      messages.searchPageSEO.interestingInformation.link,
      { linkText }
    );
    return interestingInformation;
  };

  handleDataChangeMobile = (filterState) => {
    this.setState({
      filterState
    });
  }


  render() {
    const params = get(this.props, 'match.params', {});
    const owner = get(this.props, 'match.params.owner', '');
    const makeModel = get(this.props, 'facets.makeModel', []);
    const facets = get(this.props, 'facets', {});
    const minMaxPercentilPrices = get(facets, 'minMaxPercentilPrices', []);
    const uom = get(this.context, 'uom.length.abbr');
    const customUom = this.getCustomUom();
    const defaultParams = getDefaultParams(params);
    const activeParams = getActiveParams(defaultParams);
    const { isMobileResolution, isMounted } = this.state;
    const isTypeChooserEnabled = get(
      this.context,
      'pages.searchResults.typeChooser.enabled'
    );
    const portal = getPortalName(this.context);
    const contextAdParams = get(
      this.context,
      'pages.searchResults.adsConfig',
      {}
    );
    const pageAdsConfig = merge(contextAdParams, adsConfig);
    const url = get(this.props, 'location.pathname', '');
    const maxPages = get(
      this.context,
      'pages.searchResults.pagination.maxPages',
      357
    );
    const seoMakeInfo = get(this.props, 'seoMakeInfo');
    const seoParams = meta.getSEOParams(params, makeModel, seoMakeInfo);
    const { mode, partyDetails } = this.props;
    const isSrpSeoEnabled = get(this.context, 'supports.enableSrpSeo', true);
    const videos = get(this.props, 'videos', []);
    const relatedBoatArticles = get(this.props, 'relatedBoatArticles', []);
    const wordsmithContent = get(this.props, 'wordsmithContent');
    const toggleProductsFeatureAvailable = get(
      partyDetails,
      'hasBothProductsEnginesAndBoats',
      false
    );
    const countListings = get(this.props, 'search.count', undefined);
    const listings = get(this.props, 'search.records');
    const numPages =
      getNumberOfPages(countListings, params) < maxPages
        ? getNumberOfPages(countListings, params)
        : maxPages;
    const currentPage = get(params, 'page', 1);
    let dealer;
    const isBranded = this.props.mode === MainContentV2.MODES.branded;
    const extendedSeoContent = get(
      this.context,
      'supports.extendedSeoContent',
      false
    );
    const tridentTeaserRate = get(this.props, 'tridentTeaserRate');
    const priceDisclaimer = this.getPriceDisclaimer();
    const adsData = this.props?.adsData ?? {};
    const hasAdsData = Object.keys(adsData).length > 0;
    const searchResultsContext = this.context?.pages?.searchResults;
    const adZones = searchResultsContext?.newAdsConfig?.adZones ?? {};
    const hasAdZones = Object.keys(adZones).length > 0;
    const isAdButlerSupported = !!this.context?.supports?.adButler;
    const { abTestContext, cookies } = this.props;
    const enableAdButler = isFeatureFlagActive(
      FEATURE_FLAG_KEYS.AD_BUTLER,
      abTestContext?.featureFlags,
      cookies
    ) && isAdButlerSupported;

    let newPageAdsConfig = {};
    if (enableAdButler && hasAdZones && hasAdsData) {
      newPageAdsConfig = getPageAdsData(adZones, adsData);
    }

    if (isBranded) {
      dealer = {
        id: defaultParams.owner.split('-').pop(),
        dealerLocation: get(listings, '0.owner.location.address')
      };
    }
    // will be cover in MIR-565
    /* istanbul ignore next */
    const targeting = getTargeting(
      defaultParams,
      customUom && customUom.length ? customUom.length.abbr : uom,
      dealer
    );
    const similarBoatsForOEMs = get(this.props, 'similarBoatsForOEMs');
    const metaTitle = meta.getMetaTitle({
      params: seoParams,
      maxPages: numPages,
      portal,
      isBranded,
      partyDetails,
      boatClasses: this.props.facets?.class,
      forceDynamic: ENGLISH_LOCALES.includes(this.props.intl.locale)
    });
    const metaDescription = meta.getMetaDescription({
      params: seoParams,
      maxPages: numPages,
      portal,
      isBranded,
      partyDetails,
      countListings,
      similarBoatsForOEMs,
      boatClasses: this.props.facets?.class,
      forceDynamic: ENGLISH_LOCALES.includes(this.props.intl.locale)
    });

    const supportsBrandedHeaderV2 = get(
      this.context,
      'supports.brandedHeaderV2',
      false
    );
    const contentHeadClassName =
      this.props.mode === MainContentV2.MODES.branded && supportsBrandedHeaderV2
        ? 'content-head-v2'
        : 'content-head';
    const brokerSpotlightId = get(this.props, 'spotlightDetails.party.id', 0);
    const brokerName = get(
      this.props,
      'spotlightDetails.party.details.brokerName',
      ''
    );
    const normalizedName = get(
      this.props,
      'spotlightDetails.party.details.normalizedBrokerName',
      ''
    );
    const partyName = isBranded
      ? this.getDealerWithCityText(
        get(partyDetails, 'name', ''),
        get(partyDetails, 'address.city', '')
      )
      : get(this.props, 'partyDetails.name', '');
    const breadcrumbs = getBreadCrumb(
      defaultParams,
      seoParams,
      partyName,
      brokerName,
      seoMakeInfo
    );
    const salesRep = {
      id: brokerSpotlightId,
      normalizedName
    };
    const interestingInformation = get(this.props, 'interestingInformation');
    const {
      intl: { formatMessage: t }
    } = this.props;
    const messages = this.translationMessages;
    const trackingInfo = {
      region: this.props.intl?.locale?.toUpperCase(),
      page: defaultParams.page,
      pageSize: defaultParams.pageSize,
      setProductImpression: this.props.setProductImpression
    };
    const locale = getCurrentLocale();
    const supportsTridentCalculator = get(
      getConfig(),
      `languages.${locale}.supports.tridentCalculator`,
      false
    );
    const urlParams = parseSearchParams(url);
    const currency = urlParams.currency;

    const enableFilterRedesign = get(
      this.context,
      'supports.enableFilterRedesign',
      false
    );
    const {CTA_APPLY_NOW_CALCULATOR_SRP} = this.boatLoansConstants;

    return (
      <>
        <AdProvider
          url={url}
          isWorking={this.props.isWorking}
          targeting={targeting}
          adsConfig={pageAdsConfig}
        >
          <HeroImage isMobile={isMobileResolution} />
          {countListings > 0 && !isEmpty(minMaxPercentilPrices) && (
            <VehicleSchema
              listing={listings[0]}
              makeModel={makeModel}
              metaDescription={metaDescription}
              metaName={this.getHeaderContent(seoParams, portal)}
              minMaxPrices={minMaxPercentilPrices}
              totalListings={countListings}
              url={url}
            />
          )}
          {(!isBranded || (isBranded && !isEmpty(partyDetails))) && (
            <Meta
              noindex={meta.getNoIndex(url, isBranded)}
              nofollow={meta.getNoFollow(url, isBranded)}
              canonical={meta.getCanonical(
                params,
                isBranded,
                partyDetails,
                salesRep
              )}
              next={meta.getLinkRelNext()}
              prev={meta.getLinkRelPrev()}
              title={metaTitle}
              description={metaDescription}
            />
          )}
          <div className="mobile-refine-search-height">
            {isMobileResolution && (
              <MobileRefineSearch
                {...this.props}
                customUom={customUom}
                userCurrency={currency}
                handleDataChangeMobile={(data) => this.handleDataChangeMobile(data)}
              />
            )}
          </div>
          <div className={`${contentHeadClassName} three-column`}>
            <BreadCrumb items={breadcrumbs} />
            {this.renderHeader(seoParams, portal, supportsBrandedHeaderV2)}
            {this.state.contactOpen && (
              <ContactForm
                origin={'Rep SRP'}
                open={true}
                itemId={brokerSpotlightId}
                leadType={ContactForm.TYPES.party}
                onClose={this.handleBrokerContactButtonClose}
                mode={ContactForm.MODES.modal}
                contactSubmitTrack
              />
            )}
          </div>

          <div className="three-column interesting-info">
            {extendedSeoContent &&
            currentPage === 1 &&
            !this.props.isWorking &&
            (isSingleTypeOrClassFilter(activeParams) ||
              isSingleMakeFilter(activeParams)) ? (
              <InterestingInformation
                position="top"
                isFirstPage={true}
                content={this.readOnInterestingInformation({
                  ...interestingInformation
                })}
              />
            ) : null}
          </div>

          {this.state.listingContact}

          <div className={classnames('main-content three-column-listing', {  'filter-v2': enableFilterRedesign, 'disable-actions': this.props.isWorking })} data-variant="v2">
            {!isMobileResolution && (
              <div id="left-content">
                {isTypeChooserEnabled &&
                  mode === MainContentV2.MODES.results && (
                    <TypeChooser url={url} />
                  )}
                {toggleProductsFeatureAvailable && (
                  <TypeChooserBranded url={url} owner={owner} />
                )}
                <RefineSearchSideBar
                  {...this.props}
                  customUom={customUom}
                  userCurrency={currency}
                />

                {isMounted && supportsTridentCalculator && (
                  <>
                    <PaymentCalculator
                      showMonthlyCalculatorOnly
                      tridentTeaserRate={tridentTeaserRate}
                      tridentTermInMonths={240}
                      showPreQualified={false}
                      ctaLink={CTA_APPLY_NOW_CALCULATOR_SRP}
                      useAsCard
                      supportsTridentCalculator={supportsTridentCalculator}
                    />
                  </>
                )}
              </div>
            )}

            <div id={THREE_COL_LISTINGS_SRP} className="three-column-right-content">
              {this.state.alert && (
                <AlertMessage
                  mainText={this.state.alert.mainText}
                  secondaryText={this.state.alert.secondaryText}
                />
              )}
              {similarBoatsForOEMs && (
                <AlertMessage
                  mainText={t(messages.alertMessage.similarBoatsForOEMs.title)}
                  secondaryText={t(
                    messages.alertMessage.similarBoatsForOEMs.description
                  )}
                />
              )}
              <ResultsHeader {...this.props} isMobile={isMobileResolution} filterState={this.state.filterState}/>
              {listings && (
                <ThreeColumnListings
                  sponsoredBoats={this.getSponsoredBoats()}
                  fuzzySponsoredBoats={this.getFuzzySponsoredBoats()}
                  listings={listings}
                  teaserRate={tridentTeaserRate}
                  locale={locale}
                  formatMessage={t}
                  openContactForm={
                    mode === MainContentV2.MODES.branded
                      ? this.handleBrokerContactButtonClick
                      : this.handleListingContactButtonClick
                  }
                  adParams={adParams}
                  tracking={trackingInfo}
                  setListingClick={setListingClick}
                  cookies={this.props.cookies}
                  customUom={customUom}
                  userCurrency={currency}
                  isLoading={this.props.isWorking}
                  newPageAdsConfig={newPageAdsConfig}
                />
              )}

              {!this.props.isWorking && <ResultsPagination
                {...this.props}
                maxPages={maxPages}
                history={this.props.history}
              />}
              {isMobileResolution && supportsTridentCalculator && !this.props.isWorking && (
                <>
                  <PaymentCalculator
                    initialState="closed"
                    showMonthlyCalculatorOnly
                    tridentTeaserRate={tridentTeaserRate}
                    tridentTermInMonths={240}
                    showPreQualified={false}
                    ctaLink={CTA_APPLY_NOW_CALCULATOR_SRP}
                    supportsTridentCalculator={supportsTridentCalculator}
                    useAsCard
                  />
                </>
              )}
              {priceDisclaimer && !this.props.isWorking && (
                <p className="price-disclaimer">{priceDisclaimer}</p>
              )}

              {isSrpSeoEnabled && currentPage === 1 && !this.props.isWorking ? (
                <SeoContent params={params}/>
              ) : null}

              {extendedSeoContent &&
              currentPage === 1 &&
              !this.props.isWorking ? (
                <>
                  {(isSingleTypeOrClassFilter(activeParams) ||
                    isSingleMakeFilter(activeParams)) && (
                    <InterestingInformation
                      title={this.getSeoContentH2Text(interestingInformation)}
                      position="bottom"
                      isFirstPage={true}
                      content={interestingInformation}
                    />
                  )}
                  {(videos?.length > 0 || relatedBoatArticles?.length > 0) && (
                    <ExpertReviewsTitle
                      searchParams={params}
                      t={t}
                      titleI18n={
                        messages.searchPageSEO.page.expertsReviews.heading
                      }
                      makeModel={makeModel}
                    />
                  )}
                  <YoutubeVideos videos={videos} portal={portal}/>
                  <RelatedBoatArticles
                    isFirstPage={true}
                    relatedBoatArticles={relatedBoatArticles}
                  />
                  <WordsmithContent
                    isFirstPage={true}
                    content={wordsmithContent}
                    hasMoreThanOneListing={listings?.length > 1}
                  />
                </>
              ) : null}

              {currentPage === 1 && !isEmpty(this.props.OEMDetails) ? (
                <OEMDetails
                  details={this.props.OEMDetails}
                  params={params}
                  facets={facets}
                  isLoading={this.props.isWorking}
                />
              ) : null}

              {this.props.content === null &&
              !this.props.similarBoatsForOEMs ? (
                <DynamicContent
                  params={params}
                  facets={facets}
                  count={this.props.search.count}
                  customUom={customUom}
                  seoMakeInfo={seoMakeInfo}
                />
              ) : null}

              {!this.props.similarBoatsForOEMs ? (
                <RelatedContent
                  seoMake={seoMakeInfo?.seoMakeName}
                  params={params}
                  facets={facets}
                />
              ) : null}
            </div>
            <div style={{display: 'none'}}>v2</div>
          </div>
        </AdProvider>
      </>
    );
  }
}

MainContentV2.MODES = {
  results: 'results',
  branded: 'branded'
};

MainContentV2.defaultProps = {
  mode: MainContentV2.MODES.results,
  partyDetails: {}
};

MainContentV2.propTypes = {
  cookies: PropTypes.instanceOf(Cookies),
  history: PropTypes.object,
  isWorking: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  mode: PropTypes.oneOf(Object.keys(MainContentV2.MODES)),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired
  }).isRequired,
  partyDetails: PropTypes.shape({
    bannerPath: PropTypes.string,
    description: PropTypes.string,
    logoPath: PropTypes.string,
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    displayName: PropTypes.string,
    normalizedName: PropTypes.string,
    address: PropTypes.object,
    multiLangDescriptions: PropTypes.object,
    hasBothProductsEnginesAndBoats: PropTypes.bool
  }),
  search: PropTypes.shape({
    records: PropTypes.arrayOf(listingPropTypes),
    count: PropTypes.number
  }),
  similarListings: PropTypes.shape({
    records: PropTypes.arrayOf(listingPropTypes),
    count: PropTypes.number,
    type: PropTypes.string
  }),
  params: PropTypes.object,
  sponsored: PropTypes.shape({
    records: PropTypes.arrayOf(listingPropTypes),
    count: PropTypes.number
  }),
  seoMakeInfo: PropTypes.shape({
    make: PropTypes.string.isRequired,
    seoMakeName: PropTypes.string.isRequired
  }),
  setProductImpression: PropTypes.func.isRequired,
  trackSearchCount: PropTypes.func.isRequired,
  similarBoatsForOEMs: PropTypes.bool,
  OEMDetails: listingPropTypes,
  spotlightDetails: PropTypes.shape({
    party: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      brokerSpotlight: PropTypes.bool,
      details: PropTypes.shape({
        brokerImageUrl: PropTypes.string,
        brokerName: PropTypes.string,
        normalizedBrokerName: PropTypes.string,
        brokerageLogoUrl: PropTypes.string,
        parentName: PropTypes.string,
        brokerSpotlightConnection: PropTypes.bool,
        brokerSpotlightId: PropTypes.string
      })
    })
  }),
  tridentTeaserRate: PropTypes.number,
  isLeadSubmitted: PropTypes.bool,
  ficoScores: PropTypes.array,
  content: PropTypes.object,
  facets: PropTypes.shape({
    class: PropTypes.array
  }),
  filterState: PropTypes.object,
  adsData: PropTypes.object,
  abTestContext: PropTypes.object
};

MainContentV2.contextType = PortalConfigContext;

const mapStateToProps = (state) => {
  const searchResults = get(state.app, 'data', {});
  return {
    content: get(searchResults, 'interestingInformation', null)
  };
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      setProductImpression,
      trackSearchCount
    },
    dispatch
  )
)(injectIntl(MainContentV2));
