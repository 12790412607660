import { BREAKPOINTS } from '../../../../../utils/commonHelper';

const CONTAINER_CLASS = 'container';
const LISTING_BLOCK = 'listing-block';

export const getListingBlockClass = (device, listingBlocksize, rowSize = 3) => {
  if (device !== BREAKPOINTS.desktop) {
    return CONTAINER_CLASS;
  }
  const rows = Math.ceil(listingBlocksize / rowSize);
  return `${CONTAINER_CLASS} ${LISTING_BLOCK}-${rows}`;
};
