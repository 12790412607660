export const validateSubdivisionCode = (subdivisionUrlParam) => {
  const subdivisionUrlParts = subdivisionUrlParam.split('-');
  if (subdivisionUrlParts.length > 2) {
    return false;
  }
  let codeToValidate = subdivisionUrlParts[1];
  return  !!(
    codeToValidate.match(/^(([a-zA-Z]{2})|(\d{2})|([a-zA-Z]{1}\d{2,3})|([a-zA-Z]{3}))$/)
  );
};
